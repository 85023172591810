@font-face {
  font-family: "FS SourceSansPro";
  src: local('fssourcesanspro'), url(/assets/fssourcesanspro.c68baa99.ttf) format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FS SourceSansPro";
  src: local('fssourcesanspro_italic'), url(/assets/fssourcesanspro_italic.a3ca2bb9.ttf) format('opentype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "FS SourceSansPro";
  src: local('fssourcesanspro_bold'), url(/assets/fssourcesanspro_bold.b4f73013.ttf) format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "FS SourceSansPro";
  src: local('fssourcesanspro_bold_italic'), url(/assets/fssourcesanspro_bold_italic.85c5cefb.ttf) format('opentype');
  font-weight: bold;
  font-style: italic;
}