@font-face {
  font-family: "Industry";
  src: local('industry'), url(/assets/industry.610b59fb.woff) format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Industry";
  src: local('industry_italic'), url(/assets/industry_italic.5f0b5dbe.woff) format('opentype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Industry";
  src: local('industry_bold'), url(/assets/industry_bold.332c676d.woff) format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Industry";
  src: local('industry_bold_italic'), url(/assets/industry_bold_italic.47ae7b7e.woff) format('opentype');
  font-weight: bold;
  font-style: italic;
}